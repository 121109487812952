import React, { useState } from "react";
import Link from "next/link";
import Avatar from "./avatar";
import { urlToMedia } from "../lib/content";
import { isMeet4USite } from "../lib/coreutils";

export default function ReviewCard({ review }) {
    const [showReadMore, setShowReadMore] = useState(true);
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = (e) => {
        e.preventDefault();
        setIsReadMore(!isReadMore);
    };

    const reviewHtml = review.review.replace(/(?:\r\n|\r|\n)/g, "<br>");
    const textLimit = 130;
    let text = reviewHtml;

    if (showReadMore) {
        if (text.length > textLimit) {
            text = isReadMore ? reviewHtml.slice(0, textLimit) + "..." : reviewHtml;
        } else {
            setShowReadMore(false);
        }
    }

    return (
        <div className="card card-body review-card">
            <div className="review-card__author">
                <div className="review-card__author__info">
                    <div className="players-table__participant">
                        <Avatar player={review.player} size={56} className="players-table__participant__avatar" />
                        <div>
                            <Link href={review.player.url}>
                                <a className="players-table__participant__name">{review.player.name}</a>
                            </Link>
                            {!isMeet4USite() && <span className="player-level"> [{review.player.level}]</span>}
                            <div className="players-table__participant__description">{review.player.description}</div>
                        </div>
                    </div>
                    {false && (
                        <div className="review-card__author__description review-card__date">{review.created_at}</div>
                    )}
                </div>
                <div className="review-card__author__avatar">
                    <img src={urlToMedia(review.thumbnail)} alt={review.name} />
                </div>
            </div>
            <span className="review-card__review" dangerouslySetInnerHTML={{ __html: text }} />
            {showReadMore && (
                <a href="#" className="show-all" onClick={toggleReadMore}>
                    {isReadMore ? "ещё" : "свернуть"}
                </a>
            )}
        </div>
    );
}
