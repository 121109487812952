import { useState } from "react";

import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";

import { urlToMedia } from "../lib/content";

export default function PhotoCarouselModal({ photos, startIndex, setPhotoCarouselIndex }) {
    const [index, setIndex] = useState(startIndex);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Modal
            show={true}
            centered
            dialogClassName="carousel-modal modal-lg"
            onHide={() => setPhotoCarouselIndex(false)}
        >
            <Modal.Body>
                <Carousel activeIndex={index} onSelect={handleSelect} interval={null} wrap={false}>
                    {photos.map((photo, i) => (
                        <Carousel.Item key={i}>
                            <a href={urlToMedia(photo.image)} target="_blank" rel="noreferrer" title={photo.title}>
                                <img className="d-block w-100" src={urlToMedia(photo.image)} alt={photo.title} />
                            </a>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Modal.Body>
        </Modal>
    );
}
