import { useState } from "react";

import parse, { domToReact } from "html-react-parser";

import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import EnrollmentModal from "../enrollmentmodal";
import SubscriptionModal from "../subscriptionmodal";
import { getSnippetContent } from "../../lib/content";

function InvitationPriceSelector({ prices, selectedPrice, onSelect, titleField }) {
    return (
        <Nav
            as="ul"
            className="nav nav-pills small"
            activeKey={"" + selectedPrice}
            onSelect={(selectedKey) => onSelect(parseInt(selectedKey))}
        >
            {prices.map((price, idx) => (
                <Nav.Item as="li" key={price.title_desktop}>
                    <Nav.Link eventKey={idx}>
                        <span>{price[titleField]}</span>
                    </Nav.Link>
                </Nav.Item>
            ))}
        </Nav>
    );
}

export default function Invitation({ prices, snippets, corporateEnrollmentLink, currentCity }) {
    const individualPrices = prices.filter((price) => !price.is_corporate);
    const corporatePrices = prices.filter((price) => price.is_corporate);

    const [showEnrollment, setShowEnrollment] = useState(false);
    const [initialEnrollmentType, setInitialEnrollmentType] = useState(null);
    const [subscriptionTariff, setSubscriptionTariff] = useState(false);

    const [selectedPrice, setSelectedPrice] = useState(individualPrices.length - 1); // was 0
    const price = individualPrices[selectedPrice];

    const [selectedCorporatePrice, setSelectedCorporatePrice] = useState(0);
    const corporatePrice = corporatePrices[selectedCorporatePrice];

    const showEnrollmentModal = (event, button) => {
        event.preventDefault();
        if (button == "individual") {
            if (price.enrollment_type == "subscription") {
                // setSubscriptionTariff(price.tariff);
                window.location.href = `/${currentCity.slug}/subscriptions/`;
                return;
            }
            setInitialEnrollmentType(price.enrollment_type);
        } else {
            setInitialEnrollmentType(corporatePrice.enrollment_type);
        }
        setShowEnrollment(true);
    };

    const showCorporateEnrollmentModal = (event) => {
        if (corporateEnrollmentLink && corporateEnrollmentLink.length > 0) {
            window.location.href = corporateEnrollmentLink;
        } else {
            showEnrollmentModal(event, "corporate");
        }
    };

    const parseOptions = {
        replace: (domNode) => {
            if (domNode.attribs && domNode.attribs.class === "readiness") {
                return (
                    <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={popover}>
                        <a className="invitation-card__ready-link">{domToReact(domNode.children)}</a>
                    </OverlayTrigger>
                );
            }
        },
    };

    const hideEnrollmentModal = () => setShowEnrollment(false);
    const hideSubscriptionModal = () => setSubscriptionTariff(null);

    const readinessLines = getSnippetContent(snippets, "popover-play-readiness").split(/\r\n|\r|\n/);
    const invitationDescription = getSnippetContent(snippets, "home-invitation-description") || "";
    const invitationLocation = getSnippetContent(snippets, "home-invitation-location");
    const invitationCompanyDescription = getSnippetContent(snippets, "home-invitation-company-description") || "";

    const individualButtonUrl = price.enrollment_type == "subscription" ? "subscriptions" : "enrollment";

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <div className="invitation-card__ready-popover">
                    <ul className="list-checkboxed">
                        {readinessLines.map((line, i) => (
                            <li key={i}>{line}</li>
                        ))}
                    </ul>
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className="container-fluid">
                <div className="row invitation">
                    <div className="col-12 col-sm-6">
                        <div className="card card-body invitation-card">
                            <h3>
                                <b>Приглашаем на игру</b>
                            </h3>

                            <p className="small">{invitationLocation}</p>
                            <div>{parse(invitationDescription, parseOptions)}</div>
                            <div className="d-mobile-only">
                                <div className="invitation-card__price-selector">
                                    <InvitationPriceSelector
                                        prices={individualPrices}
                                        selectedPrice={selectedPrice}
                                        onSelect={setSelectedPrice}
                                        titleField="title_mobile"
                                    />
                                </div>
                            </div>

                            <div className="d-desktop-only">
                                <div className="invitation-card__price-selector">
                                    <InvitationPriceSelector
                                        prices={individualPrices}
                                        selectedPrice={selectedPrice}
                                        onSelect={setSelectedPrice}
                                        titleField="title_desktop"
                                    />
                                </div>
                            </div>

                            {price && (
                                <>
                                    <p
                                        className="invitation-card__price"
                                        dangerouslySetInnerHTML={{ __html: price.price }}
                                    />
                                    <p>
                                        <a
                                            href={`/${currentCity.slug}/${individualButtonUrl}/`}
                                            className="btn btn-primary"
                                            onClick={(e) => showEnrollmentModal(e, "individual")}
                                        >
                                            {price.button}
                                        </a>
                                    </p>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="col-12 col-sm-6" id="order-offsite-event">
                        <div className="card card-body invitation-card">
                            <h3>
                                <b>Поиграть своей компанией</b>
                            </h3>
                            <p className="text-preline">{invitationCompanyDescription}</p>

                            <div className="d-mobile-only">
                                <div className="invitation-card__price-selector">
                                    <InvitationPriceSelector
                                        prices={corporatePrices}
                                        selectedPrice={selectedCorporatePrice}
                                        onSelect={setSelectedCorporatePrice}
                                        titleField="title_mobile"
                                    />
                                </div>
                            </div>

                            <div className="d-desktop-only">
                                <div className="invitation-card__price-selector">
                                    <InvitationPriceSelector
                                        prices={corporatePrices}
                                        selectedPrice={selectedCorporatePrice}
                                        onSelect={setSelectedCorporatePrice}
                                        titleField="title_desktop"
                                    />
                                </div>
                            </div>

                            {corporatePrice && (
                                <>
                                    <p
                                        className="invitation-card__price"
                                        dangerouslySetInnerHTML={{ __html: corporatePrice.price }}
                                    />
                                    <p>
                                        <a
                                            href={
                                                corporateEnrollmentLink && corporateEnrollmentLink.length > 0
                                                    ? corporateEnrollmentLink
                                                    : `/${currentCity.slug}/enrollment/`
                                            }
                                            className="btn btn-primary btn-wide-padding"
                                            onClick={showCorporateEnrollmentModal}
                                        >
                                            {corporatePrice.button}
                                        </a>
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {showEnrollment && (
                <EnrollmentModal
                    administratorNoteOnSuccess
                    initialEnrollmentType={initialEnrollmentType}
                    onHide={hideEnrollmentModal}
                />
            )}

            {subscriptionTariff && <SubscriptionModal tariff={subscriptionTariff} onHide={hideSubscriptionModal} />}
        </>
    );
}
