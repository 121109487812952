import { getPhoneDigits, getSnippetContent } from "../../lib/content";
import Picture from "../picture";
import ThumbnailPicture from "../thumbnailpicture";

function HomeContact({ phone, phoneLabel, hasWhatsApp, telegram }) {
    const phoneDigits = getPhoneDigits(phone);

    if (!phoneDigits || phoneDigits.length === 0) {
        return null;
    }

    return (
        <div className="home-contacts__contact">
            <a href={`tel:+${phoneDigits}`} className="text-nowrap">
                {phone}
            </a>
            <span className="text-nowrap">
                {phoneLabel}
                {hasWhatsApp && (
                    <a href={`https://wa.me/${phoneDigits}`} target="_blank" rel="noreferrer">
                        <img src="/img/whatsapp-color.svg" width="22" height="22" alt="" />
                    </a>
                )}{" "}
                {telegram && telegram.length > 0 && (
                    <a href={telegram} target="_blank" rel="noreferrer">
                        <img src="/img/telegram-color.svg" width="22" height="22" alt="" />
                    </a>
                )}
            </span>
        </div>
    );
}

export default function HomeContacts({ contacts, snippets }) {
    const email = contacts.email;
    const myPhoneDigits = getPhoneDigits(contacts.phone_my);
    const myTelegram = contacts.telegram_my;
    const showSocialContacts = contacts.phone_my_has_whatsapp || (myTelegram && myTelegram.length > 0);
    const businessDetails = getSnippetContent(snippets, "home-contacts-business-details");

    return (
        <div className="container-fluid home-contacts">
            <h3>
                <b>Контакты</b>
            </h3>

            <div className="home-contacts__cells">
                <div className="home-contacts__avatar">
                    {contacts.manager_photo ? (
                        <ThumbnailPicture
                            thumbnail={contacts.manager_photo}
                            width={98}
                            height={98}
                            alt={contacts.manager_name || "Валерия Горбачёва"}
                        />
                    ) : (
                        <Picture baseUrl="/img/valeria/valeria" alt="Валерия Горбачёва" width={98} height={98} />
                    )}
                </div>
                <div className="home-contacts__name-social">
                    <div className="home-contacts__name">{contacts.manager_name || "Валерия Горбачёва"}</div>
                    {showSocialContacts ? (
                        <div className="home-contacts__social">
                            Пишите в{" "}
                            {contacts.phone_my_has_whatsapp && (
                                <a href={`https://wa.me/${myPhoneDigits}`} target="_blank" rel="noreferrer">
                                    <img src="/img/whatsapp-color.svg" width="22" height="22" alt="" /> WhatsApp
                                </a>
                            )}{" "}
                            {contacts.phone_my_has_whatsapp && myTelegram && myTelegram.length > 0 && <>или </>}
                            {myTelegram && myTelegram.length > 0 && (
                                <a href={myTelegram} target="_blank" rel="noreferrer">
                                    <img src="/img/telegram-color.svg" width="22" height="22" alt="" /> Telegram
                                </a>
                            )}
                        </div>
                    ) : (
                        <div className="home-contacts__social">&nbsp;</div>
                    )}
                    <div className="home-contacts__inn text-preline">{businessDetails}</div>
                </div>
                <HomeContact
                    phone={contacts.phone}
                    phoneLabel={contacts.phone_label}
                    hasWhatsApp={contacts.phone_has_whatsapp}
                    telegram={contacts.telegram}
                />
                <HomeContact
                    phone={contacts.phone_enrollment}
                    phoneLabel={contacts.phone_enrollment_label}
                    hasWhatsApp={contacts.phone_enrollment_has_whatsapp}
                    telegram={contacts.telegram_enrollment}
                />
                <div className="home-contacts__contact">
                    <a href={`mailto:${email}`}>{email}</a> Почта
                </div>
            </div>
        </div>
    );
}
