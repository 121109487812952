import ThumbnailPicture from "../thumbnailpicture";

export default function HomeGameTypeCard({ type, showDetails, currentCity, rulesLink }) {
    return (
        <div className="embla__slide game-types-card">
            <div className="embla__slide__inner">
                <a href={`/${currentCity.slug}/enrollment/` + (type.id ? `?type=${type.id}` : "")}>
                    {type.thumbnail ? (
                        <ThumbnailPicture
                            thumbnail={type.thumbnail}
                            width={140}
                            height={140}
                            alt={type.name}
                            key={"pic" + type.id}
                        />
                    ) : (
                        <img src="/img/other-games.png" width="140" height="140" alt={type.name} />
                    )}
                </a>
                <div className="game-types-card__details">
                    <span className="text-nowrap">{type.name}</span>
                    {showDetails && (
                        <>
                            {type.schedule && (
                                <div className="game-types-card__details__schedule">
                                    {type.schedule.map((schedule, i) => (
                                        <p key={i}>{schedule}</p>
                                    ))}
                                </div>
                            )}
                            <a href={`/${currentCity.slug}/enrollment/` + (type.id ? `?type=${type.id}` : "")}>
                                Записаться
                            </a>
                        </>
                    )}
                </div>
                {showDetails && rulesLink && (
                    <a
                        href={`/${currentCity.slug}/games/${type.id ? "#game-" + type.id : ""} `}
                        className="game-types-card__details__link-rules"
                    >
                        (описание)
                    </a>
                )}
            </div>
        </div>
    );
}
