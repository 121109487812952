import { useState } from "react";

import Link from "next/link";

import Picture from "../picture";

import { getSnippetContent } from "../../lib/content";
import ThumbnailPicture from "../thumbnailpicture";

export default function Intro({ snippets, currentCity }) {
    const summaryHtml = getSnippetContent(snippets, "home-summary");
    const introPreface = getSnippetContent(snippets, "home-intro-preface");
    const introHtml = getSnippetContent(snippets, "home-intro");
    const founderName = getSnippetContent(snippets, "home-intro-founder-name");
    const founderRole = getSnippetContent(snippets, "home-intro-founder-role");
    const founderPhoto = getSnippetContent(snippets, "home-intro-founder-photo");
    const founderRemarkHtml = getSnippetContent(snippets, "home-intro-founder-remark");

    const [reviewExpanded, setReviewExpanded] = useState(false);

    const expandReview = (event) => {
        event.preventDefault();
        setReviewExpanded(true);
    };

    const founderNameTag = founderName.split("\n").map((name, i) => {
        if (i > 0) {
            return (
                <span key={i}>
                    <div className="d-desktop-only"></div>
                    {name}
                </span>
            );
        }
        return <span key={i}>{name} </span>;
    });

    return (
        <div className="container-fluid">
            <div className="row intro">
                <div className="col-12 col-sm-6 intro__description">
                    <div className="d-mobile-only">
                        {summaryHtml ? (
                            <div
                                className="intro__description__name"
                                dangerouslySetInnerHTML={{ __html: summaryHtml }}
                            />
                        ) : (
                            <div
                                className="intro__description__name"
                                dangerouslySetInnerHTML={{ __html: introPreface }}
                            />
                        )}
                        <div className="intro__description__about">
                            <div dangerouslySetInnerHTML={{ __html: introHtml }} />
                        </div>
                    </div>

                    <div className="d-desktop-only">
                        {summaryHtml ? (
                            <div
                                className="intro__description__name"
                                dangerouslySetInnerHTML={{ __html: summaryHtml }}
                            />
                        ) : (
                            <div
                                className="intro__description__name"
                                dangerouslySetInnerHTML={{ __html: introPreface }}
                            />
                        )}
                        <div className="intro__description__about">
                            <div dangerouslySetInnerHTML={{ __html: introHtml }} />
                        </div>
                    </div>

                    <div className="intro__description__enroll">
                        <Link href={`/${currentCity.slug}/enrollment/`}>
                            <a className="btn btn-primary">Записаться</a>
                        </Link>
                    </div>
                </div>

                <div className="col-12 col-sm-6 intro__founder-remark">
                    <div className="card card-body review-card">
                        <div className="review-card__author">
                            <div>
                                <div className="review-card__author__name">{founderNameTag}</div>
                                <div className="review-card__author__description">{founderRole}</div>
                            </div>
                            <div className="review-card__author__avatar">
                                {founderPhoto.thumbnail ? (
                                    <ThumbnailPicture
                                        thumbnail={founderPhoto.thumbnail}
                                        alt={founderName}
                                        width={200}
                                        height={200}
                                    />
                                ) : (
                                    <Picture
                                        baseUrl="/img/valeria/valeria"
                                        alt={founderName}
                                        width={200}
                                        height={200}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className={"review-card__review" + (reviewExpanded ? " show" : "")}
                            id="intro__founder-remark__review"
                        >
                            <div dangerouslySetInnerHTML={{ __html: founderRemarkHtml }} />

                            <a
                                className="intro__founder-remark__expand"
                                href="#intro__founder-remark__review"
                                onClick={expandReview}
                            >
                                <img src="/img/icons/icon-chevron-down-wide.svg" alt="" width={42} height={11} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
