import ReviewCard from "../reviewcard";
import EmblaCarousel from "../embla";

export default function Reviews({ reviews, titleDesktop }) {
    if (reviews.length == 0) {
        return null;
    }

    const slides = reviews.map((review, i) => {
        return <ReviewCard review={review} key={i} />;
    });

    return (
        <div className="container-fluid">
            <div className="reviews">
                <h3 className="d-mobile-only">
                    <b>Отзывы</b>
                </h3>
                <h3 className="d-desktop-only">
                    <b>{titleDesktop || "Отзывы о проекте"}</b>
                </h3>
                <div className="reviews__cards">
                    <EmblaCarousel slides={slides} />
                </div>
            </div>
        </div>
    );
}
